const mapBox = document.querySelector("#map-info-box");


if(mapBox){
    const mapBoxBtn = document.querySelector("#map-info-box-btn");
    const close = document.querySelector("#box-close");
    const open = document.querySelector("#box-open");

    mapBoxBtn.addEventListener("click", () => {
        mapBox.classList.toggle("hide");
        mapBoxBtn.classList.toggle("active");
        close.classList.toggle("active");
        open.classList.toggle("active");
    })
}