const widget = document.getElementById("widget-svg");

if (widget) {
  $.request("flatsList::onGetFlatsJson", {
    success: function(data) {
      const obj = JSON.parse(data.result);
      //convert object data to array
      function* values(obj) {
        for (let prop of Object.keys(obj)) yield obj[prop];
      }
      let cmsFlats = Array.from(values(obj));

      const floors = [...document.getElementsByClassName("floor")];
      const modalFloors = [...document.getElementsByClassName("modal-floor")];
      const flats = [...document.getElementsByClassName("flat")];
      const siteWrapper = document.getElementsByClassName("site-wrapper")[0];

      //floor tooltip
      const tooltip = document.getElementById("widget-tooltip");
      const tooltipCounter = document.getElementById("widget-tooltip-counter");
      siteWrapper.appendChild(tooltip);

      //flat tooltip
      const flatTooltip = document.getElementById("widget-modal-tooltip");
      const counterApartment = document.getElementById("counter-apartment");
      const counterRooms = document.getElementById("counter-rooms");
      const counterSize = document.getElementById("counter-size");
      const previewAvailability = document.getElementById(
        "preview-availability"
      );

      //modal
      const modal = document.getElementById("widget-modal");
      const modalClose = document.getElementById("modal-close");
      siteWrapper.appendChild(modal);

      //modal close handle
      modalClose.addEventListener("click", function(e) {
        e.preventDefault();
        modal.classList.remove("is-open");
        document.body.classList.remove("modal-open");
        modalFloors.forEach((floor) => {
          floor.classList.remove("active");
        });
      });

      //set flats status
      flats.forEach((flat) => {
        const flatId = flat.dataset.flat;
        const findFlat = cmsFlats.find((cmsFlat) => cmsFlat.symbol === flatId);
        if (findFlat) {
          if (findFlat.status === "wolne") {
            flat.classList.add("widget__flat-status--green");
          } else if (findFlat.status === "zarezerwowane") {
            flat.classList.add("widget__flat-status--yellow");
          } else {
            flat.classList.add("widget__flat-status--red");
          }
        } else {
          flat.classList.add("widget__flat-status--red");
        }
      });

      //actions on single floor
      const modalFloorHead = document.getElementById("widget-modal-floor");
      floors.forEach((floor) => {
        const flatsOnFloor = cmsFlats.filter(
          (flat) => flat.floor_id * 1 === floor.dataset.floor * 1
        );

        floor.addEventListener("click", function(e) {
          e.preventDefault();
          const modalFloor = modalFloors.find(
            (item) => item.dataset.floor === floor.dataset.floor
          );
          if (modalFloor) {
            modalFloor.classList.add("active");
            const floorId = modalFloor.dataset.floor;
            if (floorId === "1") {
              modalFloorHead.textContent = "parter";
            } else {
              modalFloorHead.textContent = parseInt(floorId) - 1 + " piętro";
            }
          }
          modal.classList.toggle("is-open");
          document.body.classList.toggle("modal-open");
        });

        floor.addEventListener("mouseenter", function() {
          tooltip.style.transform = "scale(1)";
          tooltip.style.opacity = "1";
          tooltipCounter.textContent = flatsOnFloor.length;
        });
        floor.addEventListener("mousemove", function(e) {
          const left = parseInt(e.pageX) + 30 + "px";
          const top = parseInt(e.pageY) + 30 + "px";

          tooltip.style.left = left;
          tooltip.style.top = top;
        });
        floor.addEventListener("mouseleave", function() {
          tooltip.style.opacity = "0";
          tooltip.style.transform = "scale(0)";
        });
      });

      //actions on single flat
      flats.forEach((flat) => {
        const flatId = flat.dataset.flat;

        const findFlat = cmsFlats.find((cmsFlat) => cmsFlat.symbol === flatId);

        if (findFlat) {
          flat.addEventListener("click", function(e) {
            e.preventDefault();
            window.location = `/mieszkania/${findFlat.id}/${findFlat.slug}`;
          });
          flat.addEventListener("mouseenter", function() {
            flatTooltip.style.transform = "scale(1)";
            flatTooltip.style.opacity = "1";

            if (findFlat.name) {
              counterApartment.textContent = findFlat.name;
              counterApartment.parentElement.style.display = "block";
            } else {
              counterApartment.parentElement.style.display = "none";
            }

            if (findFlat.rooms_number) {
              counterRooms.textContent = findFlat.rooms_number;
              counterRooms.parentElement.style.display = "block";
            } else {
              counterRooms.parentElement.style.display = "none";
            }

            if (findFlat.area) {
              counterSize.textContent = findFlat.area + " \u33A1";
              counterSize.parentElement.style.display = "block";
            } else {
              counterSize.parentElement.style.display = "none";
            }

            if (findFlat.status) {
              previewAvailability.textContent = findFlat.status;
              previewAvailability.parentElement.style.display = "block";
            } else {
              previewAvailability.parentElement.style.display = "none";
            }
          });
          flat.addEventListener("mousemove", function(e) {
            const left = parseInt(e.clientX) + 30 + "px";
            const top = parseInt(e.clientY) + 30 + "px";
            flatTooltip.style.left = left;
            flatTooltip.style.top = top;
          });
          flat.addEventListener("mouseleave", function() {
            flatTooltip.style.opacity = "0";
            flatTooltip.style.transform = "scale(0)";
          });
        }
      });
    },
  });
}
