const flatListPage = document.getElementById("flatList");

if (flatListPage) {
  const flats = [...document.getElementsByClassName("flat-list-status")];
  flats.forEach((flat) => {
    const status = flat.dataset.status;
    flat.classList.add(`flat-list__status--${status}`);
  });
}

const singleFlat = document.getElementById("flatStatus");

if (singleFlat) {
  const status = singleFlat.dataset.status;
  singleFlat.classList.add(`single-flat__status--${status}`);
}
